<template>
  <div
    ref="heroContainer"
    class="w-full bg-white pt-4 text-neutral-800 lg:h-[calc(100vh-180px)] lg:min-h-[762px] lg:pt-8"
  >
    <div class="grid-default container relative w-full pb-14 lg:pb-28 hd:pb-36">
      <div
        ref="scrollElement"
        class="absolute bottom-0 left-4 z-2 h-[300px] w-7 pb-40 pt-0 lg:bottom-9 lg:left-10 lg:h-[290px] hd:bottom-16 hd:left-20 hd:h-[390px]"
      >
        <div
          :class="isMounted ? 'lg:opacity-100' : 'lg:opacity-0'"
          class="rotate-90 text-xl transition-opacity duration-1000"
        >
          {{ $t('scroll') }}
        </div>
        <div
          class="mx-auto mt-14 h-full w-px bg-neutral-800 transition-all duration-1000"
          :class="isMounted ? 'max-h-full' : 'max-h-0'"
        />
      </div>
      <div
        ref="borderContainer"
        class="grid-default relative col-start-2 col-end-13 rounded-3xl border border-neutral-300 pb-14 transition-all duration-500 lg:h-[calc(100vh-180px)] lg:min-h-[762px] lg:pb-0 hd:rounded-[40px]"
        :class="isMounted ? 'lg:opacity-100' : 'lg:opacity-0'"
      >
        <div
          ref="textElement"
          class="relative z-2 col-span-10 pb-0 pt-7 lg:col-span-7 lg:mr-8 xl:py-20"
        >
          <Heading
            v-if="data.title"
            :generic="false"
            :content="data.title"
            :level="1"
            class="text-neutral-40 -ml-10 mb-5 lg:-ml-24 lg:mb-10"
          />
          <div
            v-if="data.html"
            class="prose mb-7 ml-auto pl-4 text-lg transition-opacity duration-1000 lg:mb-10 lg:max-w-lg lg:text-xl"
            :class="isMounted ? 'lg:opacity-100' : 'lg:opacity-0'"
            v-html="data.html"
          ></div>
          <CallToAction
            v-if="Array.isArray(data.buttons) && data.buttons.length"
            :data="data.buttons"
            class="ml-auto transition-opacity duration-1000 lg:min-w-[278px]"
            :class="isMounted ? 'lg:opacity-100' : 'lg:opacity-0'"
            filled
            theme="dark"
            :size="isLG ? 'default' : 'medium'"
          />
        </div>
      </div>
    </div>
    <div
      ref="heroImage"
      class="heroImage left-0 right-0 top-4 mx-auto h-full w-full max-w-[1280px] px-0 lg:absolute lg:top-8 lg:rounded-3xl xl:px-0 hd:max-w-[1920px] hd:rounded-[40px] hd:px-8"
    >
      <HeroLayers
        ref="layersRefs"
        class="h-full w-full rounded-[inherit]"
        :is-scrolled="isScrolled"
        :data="data.layers"
        :video="data.heroVideo?.url"
        :videoWebm="data.heroVideoWebm?.url"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import type { SectionHeroRecord } from '~/types/generated'
import { theme } from '#tailwind-config'

const breakpoints = useBreakpoints(theme.screens)
const isLG = breakpoints.greater('lg')
const isMounted = ref(false)
const { bodyLock, bodyUnlock } = useBodyLock()

defineProps({
  data: {
    type: Object as PropType<SectionHeroRecord>,
    default: () => {}
  }
})

const pageLoaded = ref(false)
const heroImage = ref<HTMLElement | null>(null)
const heroContainer = ref<HTMLElement | null>(null)
const borderContainer = ref<HTMLElement | null>(null)
const textElement = ref<HTMLElement | null>(null)
const scrollElement = ref<HTMLElement | null>(null)
const layersRefs = ref<Ref<{ imagesRefs: Element }> | null>(null)

let tween: null | any = null

const isScrolled = ref(false)

function triggerVideo() {
  isScrolled.value = true
}

onMounted(async () => {
  if (isLG.value) {
    bodyLock()
  }
  await nextTick()

  useTimeoutFn(() => {
    isMounted.value = true
  }, 800)
})

onBeforeUpdate(() => {
  pageLoaded.value = true
})

watch(pageLoaded, () => {
  if (
    heroImage.value &&
    heroContainer.value &&
    textElement.value &&
    scrollElement.value &&
    layersRefs.value &&
    pageLoaded.value &&
    isLG.value
  ) {
    gsap.registerPlugin(ScrollTrigger)

    const mm = gsap.matchMedia()

    mm.add('(min-width: 1024px)', () => {
      gsap.fromTo(
        heroImage.value,
        { clipPath: isLG.value ? 'circle(0% at right)' : 'circle(0% at 100% 30%)', opacity: 0 },
        {
          duration: 1,
          delay: 0.5,
          opacity: 1,
          clipPath: isLG.value ? 'circle(38% at right)' : 'circle(30% at 100% 30%)',
          borderRadius: 'none',
          onStart: () => {
            bodyLock()
          },
          onComplete: () => {
            bodyUnlock()
          }
        }
      )

      tween = gsap.timeline({
        ease: 'power1.in',
        scrollTrigger: {
          scrub: isLG.value ? 0.1 : 0.5,
          start: isLG.value ? 'top-=128px top' : 'top-=64px top',
          end: isLG.value ? 'top+=1000px top' : 'top+=200px top',
          markers: false,
          pin: heroContainer.value
        }
      })
      tween.to(
        borderContainer.value,
        { borderColor: 'transparent', delay: 0, onComplete: triggerVideo },
        -0.4
      )
      tween.to(textElement.value, { yPercent: -100, opacity: 0 }, 0.1)
      tween.to(scrollElement.value, { yPercent: -100, opacity: 0 }, -0.2)
      tween.fromTo(
        heroImage.value,
        { clipPath: isLG.value ? 'circle(38% at right)' : 'circle(30% at 100% 30%)' },
        {
          clipPath: isLG.value ? 'circle(150% at right)' : 'circle(150% at 100% 30%)',
          margin: '0',
          maxWidth: isLG.value ? '100vw' : 'none',
          padding: '0',
          y: isLG.value ? '-160px' : '-60px',
          borderRadius: '0',
          height: isLG.value ? 'calc(100% + 160px)' : 'calc(100% + 64px)'
        },
        0.01
      )
      gsap.utils.toArray(layersRefs.value?.imagesRefs).forEach((layer, index) => {
        tween.fromTo(
          layer,
          {
            y: isLG.value ? (index + 2) * 30 : index * 20
          },

          {
            y: '0',
            ease: 'none'
          },
          0.3
        )
      })
    })
  }
})

onBeforeUnmount(() => {
  gsap.set(heroImage.value, { clearProps: 'all' })
  tween?.kill()
})
</script>

<style lang="postcss">
.heroImage {
  @screen lg {
    clip-path: circle(0% at right);
  }
}
</style>
