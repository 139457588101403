<template>
  <div class="bg-noiseGradientDarkGreen flex transform-gpu flex-col justify-end overflow-hidden">
    <div class="hero-illustration aspect-1/2 w-full lg:aspect-2/1">
      <video
        v-if="video && isDesktop"
        ref="videoRef"
        class="layer0 absolute hidden h-full w-full scale-150 lg:block"
        muted
        playsinline
      >
        <source :src="video" type='video/mp4; codecs="hvc1"' />
        <source :src="videoWebm" type="video/webm" />
      </video>

      <div
        v-for="(item, index) in data"
        :key="index"
        ref="imagesRefs"
        class="absolute h-full w-full"
        :class="`layer${index + 1}`"
      >
        <picture
          v-if="item.desktopMedia?.responsiveImage || item.media?.responsiveImage"
          :class="[
            item.desktopMedia?.responsiveImage && !item.media?.responsiveImage && 'hidden lg:block',
            !item.desktopMedia?.responsiveImage && item.media?.responsiveImage && 'lg:hidden'
          ]"
        >
          <source
            media="(min-width: 1024px)"
            :srcset="item.desktopMedia?.responsiveImage?.src"
            sizes="(min-width: 2220px) 1248px, (min-width: 720px) 648px, calc(95vw - 17px)"
          />
          <source
            :srcset="item.media?.responsiveImage?.src"
            sizes="(min-width: 2220px) 1248px, (min-width: 720px) 648px, calc(95vw - 17px)"
          />
          <img
            :src="item.desktopMedia?.responsiveImage?.src"
            :alt="item.desktopMedia?.responsiveImage?.alt! || item.media?.responsiveImage?.alt!"
            :width="item.desktopMedia?.responsiveImage ? '1280' : '640'"
            :height="item.desktopMedia?.responsiveImage ? '960' : '1300'"
          />
        </picture>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { HeroLayerRecord } from '~/types/generated'

const videoRef = ref<HTMLVideoElement | null>(null)
const { isDesktop } = useDevice()

const props = defineProps({
  isScrolled: {
    type: Boolean,
    default: false
  },
  data: {
    type: Array as PropType<HeroLayerRecord[]>,
    default: () => []
  },
  video: {
    type: String,
    required: false
  },
  videoWebm: {
    type: String,
    required: false
  }
})

const imagesRefs = ref<HTMLElement[] | null>(null)

defineExpose({
  imagesRefs
})

watch(
  () => props.isScrolled,
  () => {
    if (props.isScrolled) {
      videoRef.value?.play()
    }
  }
)
</script>

<style lang="postcss">
.hero-illustration {
  @apply relative;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);

  img {
    @apply absolute bottom-0 left-0 h-full w-full rounded-[inherit] object-cover will-change-transform;
  }

  .layer0 {
    @apply -right-[150px] -top-[50px] left-auto;

    @screen xl {
      @apply -right-[120px] top-[50px];
    }

    @screen 2xl {
      @apply top-[150px];
    }

    @screen hd {
      @apply -right-[200px] top-[220px];
    }
  }

  .layer1 {
    @apply -right-[120px] -top-[150px] left-auto;

    @screen sm {
      @apply top-[100px];
    }

    @screen lg {
      @apply -right-[150px] -top-[200px];
    }

    @screen xl {
      @apply -top-[220px];
    }

    @screen 2xl {
      @apply -top-[120px];
    }

    @screen hd {
      @apply -top-[100px];
    }
  }

  .layer2 {
    @apply -top-[150px] left-auto right-[60px] z-[4];

    @screen sm {
      @apply top-[100px];
    }
  }

  .layer3 {
    @apply -z-[1];
  }

  .layer5 {
    @apply z-[5];
  }
}
</style>
